<template>
	<main>
		<div class="main-content">
			<div class="header bg-white border-bottom">
				<div class="container-fluid">
					<div class="header-body border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Overview</h6>
								<h1 class="header-title">
									{{ companyId ? 'Manage' : 'Add' }} Company
								</h1>
								<hr />
								<b-breadcrumb class="my-0 py-0" :items="items"></b-breadcrumb>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid">
				<router-link to="/companies/active" class="btn btn-light mb-4 btn-sm">
					Go back
				</router-link>
				<div v-if="loading" class="d-flex justify-content-center align-items-center">
					<div class="spinner-border" role="status"></div>
				</div>
				<div class="row" v-else>
					<div class="col-12 col-xl-6">
						<div class="card">
							<div class="card-header">
								<div>
									<h4 class="card-header-title">
										{{ companyId ? 'Edit' : 'Add a New' }} Company
									</h4>
								</div>
							</div>
							<div class="card-body">
								<div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorMessage">
									{{ errorMessage }}
								</div>

								<div class="d-flex justify-content-center align-items-center">
									<div class="d-flex align-items-center mr-3">
										<p style="
                        cursor: pointer;
                        background-color: black;
                        color: white;
                      " class="d-flex justify-content-center align-items-center rounded-circle mr-2 px-2" :class="[
                        currentStep === 0
                          ? 'border border-dark'
                          : 'border border-secondary',
                      ]">
											1
										</p>
										<p :class="[currentStep === 0 ? 'text-black' : 'text-muted']">
											Company information
										</p>
									</div>
									<div style="width: 40px; height: 3px; background-color: gray" class="mb-3"></div>

									<div class="d-flex align-items-center ml-3">
										<p style="
                        cursor: pointer;
                        background-color: black;
                        color: white;
                      " class="d-flex justify-content-center align-items-center rounded-circle mr-2 px-2" :class="[
                        currentStep === 0
                          ? 'border border-dark'
                          : 'border border-secondary',
                      ]">
											2
										</p>
										<p style="" :class="[currentStep === 1 ? 'text-black' : 'text-muted']">
											Admin information
										</p>
									</div>
								</div>
								<div class="mt-3">
									<keep-alive>
										<component @next="next" @completedAdminForm="handleCompleteAdminForm" @goBack="goBack()"
											:is="steps[currentStep].component"></component>
									</keep-alive>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal ref="createCorporateConfirmationModal" id="coporateCreationFormConfirmationModal" centered hide-header hide-footer no-close-on-backdrop
			no-close-on-esc no-stacking>
			<div class="d-flex flex-column justify-content-center align-items-center">
				<img src="@/assets/img/modal_info_icon.svg" alt="" />
				<h2 class="font-weight-bold py-4">Create a company</h2>
				<p class="text-muted pb-3">
					Are you sure you want to create a company ?
				</p>
				<div class="w-100 d-flex justify-content-between align-items-center py-3">
					<b-button :disabled="creatingCorporate" variant="secondary" class="w-50 mr-3"
						@click="cancelCorporateCreation()">
						Cancel
					</b-button>
					<b-button :disabled="creatingCorporate" variant="primary" class="w-50 ml-3" @click="createCorporate()">
						{{ creatingCorporate ? 'Processing' : 'Proceed' }}
					</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal ref="createCorporateSuccessModal" id="corporateCreationSuccessModal" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc
			no-stacking>
			<div class="d-flex flex-column justify-content-center align-items-center my-3">
				<img src="@/assets/img/modal_success_icon.svg" alt="" />
				<h1 class="font-weight-bold mt-3">Company created</h1>
				<p class="text-muted mt-2">
					You have successfully created a new company
				</p>
				<div class="w-100 mt-3">
					<button @click="hideSuccessModal" type="submit"
						class="btn btn-primary w-100 mt-5">
						Dismiss
					</button>
				</div>
			</div>
		</b-modal>
	</main>
</template>

<script setup>
import CompanyInformationForm from '@/views/Company/components/CompanyInformationForm.vue'
import AdminInformationForm from '@/views/Company/components/AdminInformationForm.vue'
import moment from 'moment'
import { ref, defineProps, inject } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import { useRouter } from 'vue-router/composables'


const router = useRouter()

const props = defineProps({
  companyId: {
    type: String | Number
  }
})

const toastr = inject('$toastr')
const createCorporateConfirmationModal = ref(null)
const createCorporateSuccessModal = ref(null)

const currentStep = ref(0)
const items = ref([
  {
    text: 'Dashboard',
    href: '#'
  },
  {
    text: 'Corporates',
    to: '/companies/active'
  },
  {
    text: 'Add Company',
    href: '#'
  }
])
const steps = ref([
  {
    component: CompanyInformationForm
  },
  {
    component: AdminInformationForm
  }
])
const processing = ref(false)
const errorProcessing = ref(false)
const creatingCorporate = ref(false)
const errorMessage = ref('')
const loading = ref(false)
const errorLoading = ref(false)
const companyPayload = ref({})
const company = ref({})

const fetchCompany = () => {
  loading.value = true
  errorLoading.value = false

  axiosInstance
    .get(`/v1/corporates/${props.companyId}`)
    .then((res) => {
      company.value = { ...res.data }
    })
    .catch(() => {
      errorLoading.value = true
    })
    .finally(() => (loading.value = false))
}

if (props.companyId) {
  fetchCompany()
}

const next = (payload) => {
  if (payload) {
    companyPayload.value.role = payload?.role
    companyPayload.value.staff_strength = payload?.staff_strength
    companyPayload.value.package_type = payload?.package_type
    companyPayload.value.start_date = moment(payload?.start_date).format(
      'YYYY-MM-DD'
    )
    companyPayload.value.corporate_name = payload?.corporate_name
  }
  currentStep.value += 1
}

const hideSuccessModal = () => {
  createCorporateSuccessModal.value.hide()
}
const goBack = () => {
  currentStep.value = 0
}

const previous = () => {
  if (currentStep.value !== 0) {
    currentStep.value -= 1
  }
}

const handleCompleteAdminForm = (payload) => {
  if (payload) {
    companyPayload.value.fname = payload?.fname
    companyPayload.value.lname = payload?.lname
    companyPayload.value.email = payload?.person_email
    companyPayload.value.person_phone = payload?.person_phone
    companyPayload.value.password = payload?.password
  }
  // this.$bvModal.show('coporateCreationFormConfirmationModal')
  createCorporateConfirmationModal.value.show()
}

const cancelCorporateCreation = () => {
  // this.$bvModal.hide('coporateCreationFormConfirmationModal')
  createCorporateConfirmationModal.value.hide()
}

const createCorporate = () => {
  creatingCorporate.value = true
  axiosInstance({
    method: 'POST',
    url: '/v1/corporates/',
    data: {
      ...companyPayload.value
    }
  })
    .then((res) => {
      // this.$bvModal.show('corporateCreationSuccessModal')
      createCorporateSuccessModal.value.show()
      router.push({
        name: 'ShowCompanyInfo',
        params: {
          companyId: res.data.id,
          status: 'inactive'
        }
      })
    })
    .catch((e) => {
      errorProcessing.value = true

      let msg = e.toString()
      if (e && e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message
      }

      errorMessage.value = msg

      toastr.e(errorMessage.value, 'Error')
    })
    .finally(() => (creatingCorporate.value = false))
}

</script>
